import React from "react"

function Sponsor(props) {
  return (
    <main>
      <h1>Sponsor TinyOpenSource</h1>
      <p>Stats for last 4 issues:</p>
      <ul>
        <li>278 subscribers</li>
        <li>70% open rate</li>
      </ul>
      <br />
      <p>Cost: $200 for 4 issues</p>
      <br />
      <p>
        I try to keep the sponsor copy simple like the rest of the newsletter,{" "}
        <a href="https://tinyopensource.email/2021-05-00">here's an example</a>.
      </p>
      <p>
        If you have any questions, feel free to reach out to me via{" "}
        <a href="https://camelcaseguy.com">email</a> or{" "}
        <a href="https://twitter.com/UB_Shubh">twitter DM</a>
      </p>
    </main>
  )
}

export default Sponsor
